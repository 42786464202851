@import '../../../../variables';

.container {
  position: relative;
  width: 100%;
}

.tabs {
  display: flex;
  flex-direction: column;
}
.activeTab {
  font-size: 14px;
  color: var(--color-button-primary);
  cursor: pointer;
  margin: 4px;
}
.tab {
  font-size: 14px;
  color: $oxford-blue;
  cursor: pointer;
  margin: 4px;
}
.body {
  display: flex;
  gap: 24px;
}
.body {
  font-size: 12px;
}
.content {
  border-radius: 10px;
  border: 1px solid var(--color-issabeline-grey);
  flex: 1;
  padding: 12px;
  overflow: auto;
}
.callArgumentsContainer {
  display: flex;
  flex-direction: column;
  max-height: 250px;
}
.argument {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  gap: 16px;
  margin-bottom: 8px;
}

.callResult {
  white-space: break-spaces;
}