@import 'src/variables.scss';

.container {
  position: relative;
}

.id {
  font-size: 12px;
  color: var(--color-general-ui);
  position: absolute;
  background-color: var(--color-white);
  padding: 0 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.tooltip {
  background-color: var(--color-mine-shaft);
  color: var(--color-white);
  font-size: 16px;
  border-radius: 10px;
  padding: 8px;
}
